import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';
import { HexBase64BinaryEncoding } from 'crypto';
// import * as api from '../services/api';

// 存储区
export default class ToolsStore {
  rootStore: RootStore;

  @observable t2tsInput: string;
  @observable t2tsResult: string;

  @observable ts2tInput: string;
  @observable ts2tResult: string;

  @observable encInput: string;
  @observable encResult: string;

  @observable decInput: string;
  @observable decResult: string;

  @observable secEncContentInput: string;
  @observable secEncKeyInput: string;
  @observable secEncCodecInput: HexBase64BinaryEncoding;
  @observable secEncResult: string;

  @observable secDecContentInput: string;
  @observable secDecKeyInput: string;
  @observable secDecCodecInput: HexBase64BinaryEncoding;
  @observable secDecResult: string;

  @observable hashInput: string;
  @observable hashKeyInput: string;
  @observable hashResult: string;

  @observable ipInput: string;
  @observable ipResult: string;

  @observable dnsInput: string;
  @observable dnsResult: string;

  @observable htmlInput: string;
  @observable htmlResult: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.secEncCodecInput = 'hex';
    this.secDecCodecInput = 'hex';
  }
  @action
  async getIPInfo(ip: string) {
    const response = await getIPInfo(ip);
    if (response == undefined)
      return;
    runInAction(() => {
      this.ipResult = response.json.message.location;
    })
  }

  @action
  async getDNSInfo(url: string) {
    const response = await getDNSInfo(url);
    if (response == undefined)
      return;
    runInAction(() => {
      this.dnsResult = response.json.message;
    })
  }
}

//请求区
export async function getIPInfo(ip: string) {
  return request('/api/tools/ip?ip=' + ip, {
    method: 'GET',
  })
}

export async function getDNSInfo(url: string) {
  return request('/api/tools/dns?url=' + encodeURI(url), {
    method: 'GET',
  })
}
