import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';
import { UAParser } from 'ua-parser-js';
import * as urljson from '../assets/network.json';

// 存储区
export default class NetworkCheckStore {
  rootStore: RootStore;
  @observable clientInfo: any;//{"ip":"xx","time":"xx","location":"xx"}
  @observable browserInfo: string;
  @observable osInfo: string;
  @observable deviceInfo: string;
  @observable cookieInfo: string;
  @observable storageInfo: string;
  //
  @observable currentGroup: string;
  @observable networkInfo: any; //{"CN":[{"url":"xx","ping":"25ms","status":"200","gwip":"xx"}]}

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    //
    this.clientInfo = {};
    this.browserInfo = '';
    this.osInfo = '';
    this.deviceInfo = '';
    this.cookieInfo = navigator.cookieEnabled ? 'Enable' : 'Disable';
    this.storageInfo = window.localStorage ? 'Enable' : 'Disable';
    //
    this.currentGroup = 'VC';
    this.networkInfo = {}
    for (const region in urljson['default']) {
      this.networkInfo[region] = [];
      for (const url of urljson['default'][region]) {
        this.networkInfo[region].push({ "url": url, "ping": "running", "status": 0, "ip": "" });
      }
    }
  }

  @action
  async getIPInfo() {
    const response = await getIPInfo();
    if (response == undefined)
      return;
    runInAction(() => {
      this.clientInfo = response.json.message;
    })
  }

  @action
  async getUserAgentInfo() {
    const ua = new UAParser(navigator.userAgent);
    this.browserInfo = ua.getBrowser().name + " " + ua.getBrowser().version;
    this.osInfo = ua.getOS().name + " " + ua.getOS().version;
    this.deviceInfo = ua.getDevice().vendor + ' ' + ua.getDevice().model;
  }

  @action
  async ping(group: string, times: number, upload: boolean) {
    let recording = {};
    for (let urlIndex = 0; urlIndex < this.networkInfo[group].length; urlIndex++) {
      const start = new Date().getTime();
      for (let i = 0; i < times; i++) {
        try {
          await getPing(this.networkInfo[group][urlIndex].url);
        } catch (e) { }
      }
      //更新UI
      const ping = (new Date().getTime() - start) / times;
      this.update(this.networkInfo[group][urlIndex], ping, "")
      //记录
      recording[this.networkInfo[group][urlIndex].url] = ping;
    }
    recording['ip'] = this.clientInfo.ip;
    recording['location'] = this.clientInfo.location;
    recording['language'] = navigator.language;
    //上报结果
    if (upload) {
      await uploadLog(recording)
    }
  }

  @action
  async update(object: any, ping: number, status: any) {
    object.ping = ping + 'ms';
    object.status = status;
  }
}

//请求区
export async function getIPInfo() {
  return request('/api/tools/ip', {
    method: 'GET',
  })
}

export function getPing(url: string) {
  return new Promise(function (resolve, reject) {
    var img = new Image();
    img.onload = function () { resolve(img); };
    img.onerror = function () { reject(url); };
    img.src = url + '?slf_rd=1&random=' + Math.floor((1 + Math.random()) * 0x100000).toString(16);
  });
}

export async function uploadLog(log: any) {
  return request('/api/tools/log', {
    method: 'POST',
    body: log
  })
}

