import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';

  // 存储区
export default class HotelFeizhuStore {
  rootStore: RootStore;

  @observable cityList: any;
  @observable brandList: any;
  @observable filter: any;
  @observable hotelList: any;
  @observable currentPage: number;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.cityList = [];
    this.brandList = [];
    this.filter = {
      cityList: ['110100'],
      brandList: [],
      keyword: '',
      discount: '1',
    }
    this.currentPage = 1;
  }

  /**
   * 请求区 
   */
  @action
  async getCityList() {
    const response = await hotelFeizhuCityList();
    if (response == undefined)
      return;
    if (response.success)
      runInAction(() => {
        this.cityList = response.json.message;
      })
  }

  @action
  async getBrandList() {
    const response = await hotelFeizhuBrandList();
    if (response == undefined)
      return;
    if (response.success)
      runInAction(() => {
        this.brandList = response.json.message;
      })
  }

  @action
  async search() {
    const response = await hotelFeizhuSearch(this.filter);
    if (response == undefined)
      return;
    if (response.success)
      runInAction(() => {
        this.hotelList = response.json.message.hits.hits;
      })
  }
}

export async function hotelFeizhuCityList() {
  return request('/api/feizhu/getCityList', {
    method: 'POST',
  })
}

export async function hotelFeizhuBrandList() {
  return request('/api/feizhu/getBrandList', {
    method: 'POST',
  })
}

export async function hotelFeizhuSearch(body: any) {
  return request('/api/feizhu/search', {
    method: 'POST',
    body: body,
  })
}