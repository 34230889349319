import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';

// 存储区
export default class StockStore {
  rootStore: RootStore;
  @observable allCodes = new Set();
  @observable selectedCodes: any = [];
  @observable yearRange = '365';
  @observable allData: any = []; //全部数据
  @observable showData: any = []; //处理后的数据
  dataMap = {}; //datamap[code][date]=item
  earliestDate = new Date('2050-01-01');
  endDate = new Date('2000-01-01');
  codeVar = {};//{code:{startDate}}


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  async getData(chart: any) {
    const response = await getData()
    if (response == undefined)
      return;
    runInAction(() => {
      if (response.status == 200) {
        this.allData = response.json.message;
        this.updateOptions()
        this.updateData()
        chart.changeData(this.showData)
      }
    })
  }

  @action
  async updateOptions() {
    this.allCodes = new Set();
    for (const item of this.allData) {
      this.allCodes.add(item['code'])
    }
    this.selectedCodes = [];
    const items: any = this.allCodes.values()
    for (const item of items) {
      this.selectedCodes.push(item)
    }
  }
  @action
  async updateData() {
    console.info(new Date());
    for (const item of this.allData) {
      const code = item['code'];
      const cdate = new Date(item['cdate']);//将字符串转为日期
      item['cdate'] = cdate
      if (this.dataMap[code] == undefined) {
        this.dataMap[code] = {}
      }
      //放入map便于寻找
      this.dataMap[code][cdate] = item
      if (cdate > this.endDate) {
        this.endDate = cdate
      }
      if (cdate < this.earliestDate) {
        this.earliestDate = cdate
      }
    }
    // console.log(this.earliestDate)
    // console.log(this.endDate)
    const startTS = this.endDate.getTime() - 86400000 * parseInt(this.yearRange);
    //如果当前日期没有数据，拿前一天的来补
    this.showData = [];
    for (let i = this.earliestDate.getTime(); i <= this.endDate.getTime(); i += 86400000) {
      const currentDate = new Date(i)
      const beforeDate = new Date(i - 86400000)
      for (const code of this.selectedCodes) {
        //当天为空
        if (this.dataMap[code][currentDate] == undefined) {
          if (this.dataMap[code][beforeDate] != undefined) {
            this.dataMap[code][currentDate] = {}
            this.dataMap[code][currentDate]['cdate'] = currentDate
            this.dataMap[code][currentDate]['code'] = code
            this.dataMap[code][currentDate]['value'] = this.dataMap[code][beforeDate]['value']
          }
        }
        if (i >= startTS) {
          this.showData.push(this.dataMap[code][currentDate]) //写入数据
        }
      }
    }
    console.info(this.showData[1]);
  }
}

//请求区
export async function getData() {
  return request('/api/stock/getData', {
    method: 'GET',
  })
}

