import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';

// 存储区
export default class ChatStore {
  rootStore: RootStore;
  @observable code: string;
  @observable userId: string;
  @observable messageList: any[];
  @observable input: string;
  @observable uploadInfo: any;


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.code = window.location.pathname.substring(window.location.pathname.length - 6);
    this.userId = '';
    this.messageList = [];
    this.input = '';
  }

  @action
  async get(store: ChatStore) {
    const response = await getRequest(store.code);
    if (response == undefined)
      return;
    runInAction(() => {
      if (response.status == 200) {
        store.userId = response.json.message.userId
        store.messageList = response.json.message.messageList
      }
    })
  }

  @action
  async send() {
    const response = await sendRequest(this.code, this.input, { 'msgType': 'text', 'text': this.input });
    if (response == undefined)
      return;
    runInAction(() => {
      if (response.status == 200) {
        if (this.messageList.length == 10) {
          this.messageList.shift()
        }
        this.messageList.push(response.json.message)
      }
    })
  }

  @action
  async getFileUrl(fileName: string) {
    const response = await getFileUrlRequest(fileName)
    if (response == undefined)
      return;
    runInAction(() => {
      if (response.status == 200) {
        this.uploadInfo = response.json.message
      }
    })
  }

}

//请求区
export async function getRequest(code: string) {
  return request('/api/chat/get?code=' + code, {
    method: 'GET',
  })
}

export async function sendRequest(code: string, text: string, body: any) {
  return request('/api/chat/send?code=' + code, {
    method: 'POST',
    body: body,
  })
}

export async function getFileUrlRequest(fileName: string) {
  return request('/api/chat/getFileUrl?fileName=' + fileName, {
    method: 'GET',
  })
}

