import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';

//存储区
export default class CompanyStore {
  rootStore: RootStore;

  @observable list: any;
  @observable input: any;
  @observable currentPage: number;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.list = [];
    this.input = '';
    this.currentPage = 1;
  }

  @action
  async getTaskList() {
    const response = await getCompanyTaskList();
    if (response == undefined)
      return;
    runInAction(() => {
      this.list = response.json.message;
      this.currentPage = 1;
    })
  }

  @action
  async newTask() {
    const body = { companyName: this.input };
    const response = await postNewCompanyTask(body);
    if (response == undefined)
      return;
    runInAction(() => {
      this.list = response.json.message;
      this.currentPage = 1;
    })
  }

}

export async function getCompanyTaskList() {
  return request('/api/company/getTaskList', {
    method: 'GET',
  })
}

export async function postNewCompanyTask(body: any) {
  return request('/api/company/newTask', {
    method: 'POST',
    body: body,
  })
}
