import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';

//存储区
export default class ShenzhenMapStore {
  rootStore: RootStore;

  @observable list: any;
  @observable showList: any;
  @observable center: any;
  @observable zoom: any;


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.list = [];
    this.showList = [];
    this.center = { lng: 113.9519064, lat: 22.5447 }
    this.zoom = 12;
  }


  @action
  async getMapList() {
    const response = await getShenzhenMapList()
    if (response == undefined)
      return;
    runInAction(() => {
      this.list = response;
    })
  }

}

//请求区
export async function getShenzhenMapList() {
  return request('https://quickutil.oss-cn-shenzhen.aliyuncs.com/step3.json', {
    method: 'GET',
  })
}
