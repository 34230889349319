// import * as Raven from 'raven-js';
import * as React from 'react';

class ErrorBoundary extends React.Component<{ children: any }> {
  componentDidCatch (error: any, info: any) {
    // Display fallback UI
    // this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    // Raven.captureException(error, { extra: info });
  }

  render () {
    return this.props.children;
  }
}

export default ErrorBoundary;
