import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import * as dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import './index.less';
import { rootStore } from './stores/RootStore';

// enable MobX strict mode
configure({ enforceActions: 'observed' });

dayjs.locale('zh-cn');

const renderApp = () => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={rootStore}>
        <App />
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root') as HTMLElement,
  );
};

renderApp();

// hot module reload
// TODO add react-hot-loader so state will be preserved
declare const module: { hot: any };
if (module.hot) {
  module.hot.accept(['./App'], () => {
    renderApp();
  });
}
