import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router';
import { IGlobalReactProps } from '../../App';

const Authorization = (permissions?: string | string[]) => {
  return (Comp: any) => {
    class AuthorizationWrap extends React.Component<IGlobalReactProps> {
      render () {
        const { store: storeObj, ...rest } = this.props;
        const hasPermission = true;
        return hasPermission ? (
          <Comp {...rest} />
        ) : (
          <Redirect to="/exception/404" />
        );
      }
    }
    return inject('store')(observer(AuthorizationWrap));
  };
};

export default Authorization;
