import { Button } from 'antd';
import * as React from 'react';
import * as styles from './index.module.less';
import config from './typeConfig';

interface IExceptionProps {
  type: '403' | '404' | '500';
  title?: string;
  desc?: string;
  img?: string;
  actions?: React.ReactNode;
  linkElement: React.ReactNode;
  style?: React.CSSProperties;
}

export default (props: IExceptionProps) => {
  const pageType = props.type in config ? props.type : '404';
  // const clsString = classNames(styles.exception);
  return (
    // <div className={clsString} style={props.style}>
    <div style={props.style}>
      <div className={styles.imgBlock}>
        <div
          className={styles.imgEle}
          style={{
            backgroundImage: `url(${props.img || config[pageType].img})`,
          }}
        />
      </div>
      <div className={styles.content}>
        <h1>{props.title || config[pageType].title}</h1>
        <div className={styles.desc}>{props.desc || config[pageType].desc}</div>
        <div className={styles.actions}>
          {props.actions ||
            React.createElement(
              'a',
              {
                to: '/',
                href: '/',
              },
              <Button type="primary">返回首页</Button>,
            )}
        </div>
      </div>
    </div>
  );
};
