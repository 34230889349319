import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';

// 存储区
export default class WeddingStore {
  rootStore: RootStore;

  @observable recording: string;
  @observable wxconfig: {};


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.wxconfig = {};
  }

  @action
  async getWXConfig(wx: any) {
    const response = await getWXConfig();
    if (response == undefined)
      return;
    runInAction(() => {
      this.wxconfig = response.json.message;
      this.wxconfig['debug'] = false;
      this.wxconfig['jsApiList'] = []
      wx.config(this.wxconfig);
    })
  }
}

//请求区
export async function getWXConfig() {
  return request('/api/wx/getJSSignature', {
    method: 'GET',
  })
}

