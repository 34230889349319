import * as React from 'react';
import { Router } from 'react-router';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { RootStore } from './stores/RootStore';
// import { rootStore } from './stores/RootStore';
import { createBrowserHistory } from 'history';
import PCLayout from './views/PCView/PCLayout';
import PlogView from './views/PCView/WeddingPlogView';
import DressView from './views/PCView/WeddingDressView';

export interface IGlobalReactProps extends RouteComponentProps<any> {
  store: RootStore;
}

class App extends React.Component {
  render() {
    return (
      // <Router history={rootStore.PCRouterStore.history}>
      <Router history={createBrowserHistory()}>
        <Switch>
          <Route path="/plog" component={PlogView} />
          <Route path="/weddingdress" component={DressView} />
          <Route path="/" component={PCLayout} />
        </Switch>
      </Router>
    );
  }
}

export default App;
