import { Spin } from 'antd';
import * as React from 'react';

import * as styles from './index.module.less';

export default () => (
  <div className={styles.loading}>
    <Spin />
  </div>
);
