import { observable, action } from 'mobx';
import { RootStore } from './RootStore';

export default class BasicLayoutStore {
  rootStore: RootStore;
  @observable sideMenuCollapsed: boolean;
  @observable recordNumber: string;
  @observable headCenterText: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.sideMenuCollapsed = true;
    this.displayRecordNumber();
    this.headCenterText = '';
  }

  @action
  setHeader(text: string) {
    this.headCenterText = text
  }

  @action
  toggleSideMenu() {
    this.sideMenuCollapsed = !this.sideMenuCollapsed;
    this.displayRecordNumber();
  }

  displayRecordNumber() {
    const isMobile = /Mobi/.test(navigator.userAgent);//判断设备
    if (this.sideMenuCollapsed || isMobile) {
      this.recordNumber = ''
    } else {
      this.recordNumber = '粤ICP备15061904号-4'
    }
  }
}
