import { Layout } from 'antd';
import Authorization from '../../components/Authorization';
import Loading from '../../components/Loading';
import * as Loadable from 'react-loadable';
import { Bind } from 'lodash-decorators';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Route, Switch } from 'react-router-dom';
import { IGlobalReactProps } from '../../App';
import { observer } from 'mobx-react';
import GlobalHeader from '../../components/GlobalHeader';
import SideMenu from '../../components/SideMenu';
import NotFound from '../ExceptionView/404';
import { ToolOutlined, DashboardOutlined } from '@ant-design/icons';
import { rootStore } from '../../stores/RootStore';
import * as styles from './PCLayout.module.less';
import logo from '../../assets/image/logo.png';

const { Content } = Layout;
interface IRouterItem {
  component?: React.ComponentType;
  path?: string;
  exact?: boolean;
  strict?: boolean;
  auth?: string | string[];
}

function generateRouteConfig(route: IRouterItem[]) {
  return route.map(item => {
    return {
      key: item.path,
      exact: typeof item.exact === 'undefined' ? true : item.exact,
      ...item,
      component: Authorization(item.auth)(item.component),
    };
  });
}

function asyncLoad(loader: () => Promise<any>) {
  return Loadable({
    loader,
    loading: props => {
      if (props.pastDelay) {
        return <Loading />;
      } else {
        return null;
      }
    },
    delay: 500,
  });
}

//路由支持的配置
const routeConfig = [
  //首页设置
  {
    path: '/',
    component: asyncLoad(() => import('./ToolsView')),
  },
  {
    path: '/tools',
    component: asyncLoad(() => import('./ToolsView')),
  },
  {
    path: '/check',
    component: asyncLoad(() => import('./NetworkCheckView')),
  },
  {
    path: '/chat/*',
    component: asyncLoad(() => import('./ChatView')),
  },
  {
    path: '/charts',
    component: asyncLoad(() => import('./ChartView')),
  },
  {
    path: '/stock',
    component: asyncLoad(() => import('./StockView')),
  },
  {
    path: '/company',
    component: asyncLoad(() => import('./CompanyView')),
  },
  {
    path: '/hotel/feizhu',
    component: asyncLoad(() => import('./HotelFeizhuView')),
  },
  {
    path: '/hotel/agoda',
    component: asyncLoad(() => import('./HotelAgodaView')),
  },
  // {
  //   path: '/map',
  //   component: asyncLoad(() => import('./ShenzhenMapView')),
  // },
  // 异常页路由
  {
    path: '/exception/403',
    component: asyncLoad(() => import('../ExceptionView/403')),
  },
  {
    path: '/exception/404',
    component: asyncLoad(() => import('../ExceptionView/404')),
  },
  {
    path: '/exception/500',
    component: asyncLoad(() => import('../ExceptionView/500')),
  },
];

// 展示的menu
const menuConfig = [
  {
    name: '常用工具',
    iconObject: (<ToolOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />),
    path: '/tools',
  },
  {
    name: '网络检测',
    iconObject: (<DashboardOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />),
    path: '/check',
  }
];

@observer
class PCLayout extends React.Component<IGlobalReactProps> {

  @Bind()
  toggle() {
    rootStore.PCLayoutStore.toggleSideMenu();
  }

  @Bind()
  handleMenuClick({ key }: any) {
    if (key === 'login') {
      alert("登录成功");
      // this.props.history.push('/member/my-profile');
    } else if (key === 'logout') {
      alert("注销成功");
      // window.location.href = '/logout';
    }
  }

  render() {
    return (
      <DocumentTitle title="QuickUtil">
        <Layout style={{ minHeight: '100vh' }}>
          {/* 左侧边栏 */}
          <SideMenu title='QuickUtil'
            collapsed={rootStore.PCLayoutStore.sideMenuCollapsed}
            menuData={menuConfig}
            pathname={this.props.history.location.pathname}
            logo={logo}>
            <div className={styles.footer}><a href="https://beian.miit.gov.cn" target="_blank" rel="noopener">{rootStore.PCLayoutStore.recordNumber}</a></div>
          </SideMenu>
          <Layout>
            {/* 上部Header */}
            <GlobalHeader
              collapsed={rootStore.PCLayoutStore.sideMenuCollapsed}
              toggle={this.toggle}
              onMenuClick={this.handleMenuClick}
              centerText={rootStore.PCLayoutStore.headCenterText}
            />
            {/* 中心页面 */}
            <Content>
              <Switch>
                {generateRouteConfig(routeConfig).map((item: IRouterItem) => (
                  <Route
                    key={item.path}
                    path={item.path}
                    component={item.component}
                    exact={item.exact}
                  />
                ))}
                <Route component={NotFound} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </DocumentTitle>
    );
  }
}

export default PCLayout;
