import { observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import request from '../utils/request';
import * as HotelAPI from './HotelFeizhuStore'

  // 存储区
export default class HoteAgodaStore {
  rootStore: RootStore;

  @observable cityList: any;
  @observable brandList: any;
  @observable filter: any;
  @observable hotelList: any;
  @observable currentPage: number;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.cityList = [];
    this.brandList = [];
    this.filter = {
      cityList: ['318'],
      brandList: [],
      keyword: '',
      discount: '1',
    }
    this.currentPage = 1;
  }

  @action
  async getCityList() {
    const response = await hotelAgodalCityList();
    if (response == undefined)
      return;
    if (response.json.success)
      runInAction(() => {
        this.cityList = response.json.message;
      })
  }

  @action
  async getBrandList() {
    const response = await HotelAPI.hotelFeizhuBrandList();
    if (response == undefined)
      return;
    if (response.json.success)
      runInAction(() => {
        this.brandList = response.json.message;
      })
  }

  @action
  async search() {
    const response = await hotelAgodaSearch(this.filter);
    if (response == undefined)
      return;
    if (response.json.success)
      runInAction(() => {
        this.hotelList = response.json.message.hits.hits;
      })
  }
}

//请求区
export async function hotelAgodalCityList() {
  return request('/api/agoda/getCityList', {
    method: 'POST',
  })
}


export async function hotelAgodaSearch(body: any) {
  return request('/api/agoda/search', {
    method: 'POST',
    body: body,
  })
}


