import { Layout, Menu, Dropdown, Avatar } from 'antd';
import { LoginOutlined, LogoutOutlined, UserOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import * as React from 'react';
import * as styles from './index.module.less';

interface IGlobalHeaderProps {
  collapsed?: boolean;
  toggle?: React.MouseEventHandler<any>;
  onMenuClick?: (param: any) => void;
  centerText?: string;
}

const { Header } = Layout;

class GlobalHeader extends React.PureComponent<IGlobalHeaderProps> {

  render() {
    const { onMenuClick } = this.props;

    const menu = (
      <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item key="login">
          <LoginOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />登录
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <LogoutOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />退出登录
        </Menu.Item>
      </Menu>
    );

    return (

      <Header className={styles.header}>
        {this.props.collapsed ?
          <MenuUnfoldOutlined
            className={styles.trigger}
            onClick={this.props.toggle}
            translate={undefined}
            onAuxClick={undefined}
            onAuxClickCapture={undefined} /> :
          <MenuFoldOutlined
            className={styles.trigger}
            onClick={this.props.toggle}
            translate={undefined}
            onAuxClick={undefined}
            onAuxClickCapture={undefined} />
        }
        {this.props.collapsed ?
          <span className={styles.center}>{this.props.centerText}</span> :
          <span></span>
        }
        <div className={styles.right}>
          <Dropdown overlay={menu}>
            <span className={`${styles.action} ${styles.account}`}>
              <Avatar className={styles.avatar} icon={<UserOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />} />
              {/* <span className={styles.name}>未登录</span> */}
            </span>
          </Dropdown>
        </div>
      </Header>
    );
  }
}

export default GlobalHeader;
