// import { notification, message } from 'antd';
import * as Cookies from 'js-cookie';

interface IRequestInit extends RequestInit {
  body?: any;
}


function parseJson(response: Response) {
  if (response.status >= 400) {
    const error = new Error();
    error.message = response.url;
    error.name = String(response.status);
    throw error;
  }
  const status = response.status;
  const headers = response.headers;
  return response.json().then(json => {
    return { 'status': status, 'headers': headers, 'json': json };
  });
}

export default function request(
  url: string,
  options?: IRequestInit,
  throwException: boolean = false,
): Promise<any> {
  const defaultOptions: RequestInit = {
    credentials: 'include',
    headers: {
      'x-csrf-token': Cookies.get('csrfToken') as string,
    },
  };
  const newOptions = { ...defaultOptions, ...options };

  if (!(newOptions.body instanceof FormData)) {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers,
    };
    newOptions.body = JSON.stringify(newOptions.body);
  } else {
    newOptions.headers = {
      Accept: 'application/json',
      ...newOptions.headers,
    };
  }

  return fetch(url, newOptions)
    .then((response: Response) => {
      return parseJson(response)
    })
    .catch((e: Error) => {
      // console.info(e.message)
      // console.info(e.name)
      // console.info(e.stack)
      // message.error(e.message);
      // const status = Number(e.name);
      // if (status === 403) {
      //   rootStore.PCRouterStore.push('/exception/403');
      // }
      // else if (status <= 504 && status >= 500) {
      //   rootStore.Router.push('/exception/500');
      // }
    });
}
